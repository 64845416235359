import { getStorageValue } from "helpers";
import useUser from "hooks/useUser";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";

export default function Analytics({ showBanner }: { showBanner: boolean }) {
	const GTM_ID = process.env.NEXT_PUBLIC_GTM_TAG;
	const { isLoggedIn, userRef } = useUser();
	const router = useRouter();
	const [isTrackable, setIsTrackable] = useState(false);

	useEffect(() => {
		if (getStorageValue("cookie-confirm") === "yes" && router.pathname.startsWith("/quick-widget")) {
			setIsTrackable(true);
		}
	}, [router.pathname, showBanner]);

	if (router.pathname.startsWith("/quick-widget") && !isTrackable) {
		return null;
	}

	return (
		<>
			<noscript>
				<iframe src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`} height="0" width="0" style={{ display: "none", visibility: "hidden" }} />
			</noscript>
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${GTM_ID}');
  `,
				}}
			/>

			<Script
				id="tiktok-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
        !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                  ttq.load('CM22P4RC77U4KJBR1IKG');
                  ttq.page();
                }(window, document, 'ttq');
  `,
				}}
			/>

			<Script
				id="hotjar-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
				(function(h,o,t,j,a,r){
					h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
					h._hjSettings={hjid:3836946,hjsv:6};
					a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');r.async=1;
					r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
					a.appendChild(r);
				})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
				}}
			/>

			<Script strategy="afterInteractive" async src="//static.getclicky.com/js" data-id="101422036" />

			<Script
				id="facebook-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			n.callMethod.apply(n,arguments):n.queue.push(arguments)};
			if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
			n.queue=[];t=b.createElement(e);t.async=!0;
			t.src=v;s=b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t,s)}(window,document,'script',
			'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '327066073539205');
			fbq('track', 'PageView');
  `,
				}}
			/>

			{userRef && isLoggedIn && (
				<Script
					id="userId-script"
					strategy="afterInteractive"
					dangerouslySetInnerHTML={{
						__html: `
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
						'user_id': '${userRef}'
						});
  					`,
					}}
				/>
			)}
		</>
	);
}
